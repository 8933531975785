<style>
  div {
    background: white;
    padding: 1rem;
  }
</style>

<div>
  <slot />
</div>
