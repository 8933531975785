<style>
  div {
    display: grid;
    max-width: 1200px;
    margin: auto;
  }
</style>

<div>
  <slot />
</div>
