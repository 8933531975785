<style>
  div {
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
  }
</style>

<div>
  <slot />
</div>
