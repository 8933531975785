<style>
  button {
    cursor: pointer;
    padding: 1rem 3rem;
    margin: 0;
    background: hsla(0, 50%, 50%, 0.5);
    color: white;
    border: none;
    border-radius: 0;
    font-weight: 500;
    border-radius: 100px;
  }
</style>

<button on:click>
  <slot />
</button>
