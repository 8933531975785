<script>
  import Card from "../atoms/Card.svelte";
  export let title;
  export let hotel;
  export let content;

  let open = false;
  const toggle = () => (open = !open);
</script>

<style>
  a {
    color: black;
  }
</style>

<Card>
  <a href={hotel.bookingUrl}>
    <h4>{hotel.name} - {hotel.city}</h4>
  </a>
  <h2>{title}</h2>
  <p>{content}</p>
</Card>
