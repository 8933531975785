<script>
  import Grid from "../atoms/Grid.svelte";
  import Button from "../atoms/Button.svelte";
</script>

<style>
  .header {
    display: grid;
    padding: 1rem;
    place-content: center;
    justify-content: space-between;
  }
  h1 {
    font-weight: 900;
    margin: 0;
  }
</style>

<div class="header">
  <h1>
    Hotel
    <span style="color:grey;">Bedbug</span>
    Registry
  </h1>
  <h2>Easiest way to avoid bed bugs</h2>
</div>
